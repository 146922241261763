import { Type } from "class-transformer";
import moment from "moment";
import {Company} from "@/model/api/Company";

export class InvoiceFilters {
    @Type(() => Date)
    from_to: Date[];

    get from() {
        let from = this.from_to?.[0];
        if (from) {
            from = new Date(new Date(from).setHours(0, 0, 0, 0));
            return from;
        }

        return null;
    }

    get to() {
        let to = this.from_to?.[1];
        if (to) {
            to = new Date(new Date(to).setHours(23, 59, 59, 999));
            return to;
        }

        return null;
    }

    client: Company;

    constructor() {
        const from = new Date();
        from.setHours(0, 0, 0, 0);
        this.from_to = [from];
    }
}
