import {Options, Vue} from "vue-property-decorator";
import {AppTable} from "@components";
import {FilterMatchMode} from "primevue/api";
import {SessionStorageEnum} from "@/utils/SessionStorageEnum";
import {InvoicesRoutesEnum} from "@/modules/invoices/router";
import {invoicesService} from "@services/invoices.service";
import {InvoiceTypeEnum} from "@/model/api/Invoice";
import {fileDownload, getFileNameFromHeader} from "@/utils/utils";
import {CompaniesRoutesEnum} from "@/modules/companies/router";
import {UsersRoutesEnum} from "@/modules/users/router";
import InvoicesFilters from "@/modules/invoices/pages/invoices/invoicesFilters.vue";
import {InvoiceFilters} from "@/modules/invoices/pages/invoices/InvoiceFilters";
import Table from "@components/AppTable/Table";
import moment from "moment";

@Options({
    name: 'InvoicesPage',

    components: {
        AppTable,
        InvoicesFilters
    },

    beforeRouteEnter(to, from, next) {
        if (from.name && !(from.name as string).startsWith(InvoicesRoutesEnum.PREFIX)) {
            sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_INVOICES);
            sessionStorage.removeItem(SessionStorageEnum.INVOICES_ADVANCED_FILTERS);
        }

        next();
    },

    beforeRouteLeave() {
        if (this.service?.cancelPendingRequests) {
            this.service.cancelPendingRequests();
        }
    },
})
export default class InvoicesPage extends Vue {
    filters: any = null;

    advFilters: InvoiceFilters = new InvoiceFilters();

    get service() {
        return invoicesService;
    }

    get stateKey() {
        return SessionStorageEnum.TABLE_STATE_INVOICES
    }

    get typesOptions() {
        return Object.keys(InvoiceTypeEnum).map((key) => {
            return { label: this.$t(`invoices.type_${InvoiceTypeEnum[key]}`), value: InvoiceTypeEnum[key] }
        })
    }

    async downloadInvoices(id: number){
        this.$waitFor(
            async () => {
                const response = await invoicesService.downloadInvoices(id)
                try {
                    const fileName = getFileNameFromHeader(response.headers)
                    fileDownload(
                        fileName,
                        response.data,
                        response.headers['content-type']
                    )
                } catch (err) {
                    this.$errorMessage(this.$t('Operation failed'))
                }
            }
        );
    }

    onReset() {
        this.advFilters = new InvoiceFilters();
    }

    onFiltersChange() {
        const table = (this.$refs.dataTable as Table);

        if (table?.filters) {
            const filters = table.filters;

            if (this.advFilters.from && this.advFilters.to) {
                const from = moment(this.advFilters.from);
                const to = moment(this.advFilters.to);

                filters.date_at.value = [from, to];
                filters.date_at.matchMode = FilterMatchMode.BETWEEN;
            } else {
                filters.date_at.value = moment(this.advFilters.from);
                filters.date_at.matchMode = FilterMatchMode.GREATER_THAN_OR_EQUAL_TO;
            }
        }

        table.applyFilter();
    }

    private initFilter() {
        this.filters = {
            date_at: {
                value: this.advFilters.from,
                matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            },
            business_zip: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            business_country: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            business_city: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            business_address: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            business_name: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            mov_id: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            type: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
        };
    }

    async goToDetails(invoice: any) {

        if(invoice.type === InvoiceTypeEnum.SYSTEM){
            return
        }

        let payload

        switch (invoice.type){
            case InvoiceTypeEnum.BROKER:
                payload = {
                    name: CompaniesRoutesEnum.BROKER_DETAIL,
                    params: { companyId: invoice.broker_id }
                }
                break;
            case InvoiceTypeEnum.CUSTOMER:
                payload = {
                    name: CompaniesRoutesEnum.CUSTOMER_DETAIL,
                    params: { companyId: invoice.customer_id }
                }
                break;
            case InvoiceTypeEnum.DRIVER:
                payload = {
                    name: UsersRoutesEnum.USER_DETAIL,
                    params: { userId: invoice.driver_id }
                }
                break;
            case InvoiceTypeEnum.CLIENT:
                payload = {
                    name: CompaniesRoutesEnum.CLIENT_DETAIL,
                    params: { companyId: invoice.client_id }
                }
                break;
            default:
                break
        }
        const routeData = this.$router.resolve(payload);
        window.open(routeData.href, '_blank');
    }

    created() {
        this.initFilter();
    }

}
